import { computed, decorate, observable, runInAction } from 'mobx';
import { Config, Picker, IsomorphicTransport } from '@fbinhouse/spark-client';
import { OptionStore } from './optionStore';
import axios from 'axios';

/**
 * CIS Picker
 *
 * TODO: Split in to multiple single purpose stores and improve API
 *
 * Initialised with a CIS code from the Cis component. Any existing option data
 * (colors, packages) should also be initialised some where.
 *
 * Call the async method getData to receive a plain object of data
 * to persist.
 */
export class PickerStore {
    client = null;

    stateUpdate = null;

    models = [];
    selectedModelId = null;
    selectedModelName = '';
    selectedOptionsTabKey = '';
    cisCode = '';
    salesVersion = '';
    carData = {};

    menus = [
        {
            key: 'engine',
            menuId: 'SUBMENU-ENGIN',
            label: 'Motor',
            emptyString: 'Select engine...',
            value: '',
            items: []
        },
        {
            key: 'transmission',
            menuId: 'SUBMENU-TRANSMISSION',
            label: 'Växellåda',
            emptyString: 'Select transmission...',
            value: '',
            items: []
        },
        {
            key: 'salesVersion',
            menuId: 'SUBMENU-SALESVERSION',
            label: 'Utrustningsnivå',
            emptyString: 'Select sales version...',
            value: '',
            items: []
        },
        {
            key: 'kampanjer',
            menuId: 'SUBMENU-KAMPANJER',
            label: 'Erbjudanden',
            emptyString: 'Select erbjudande...',
            value: '',
            items: []
        }
    ];

    options = [
        new OptionStore('package', 'SUBMENU-PACKAGES', 'Paket', true),
        new OptionStore('color', 'SUBMENU-COLOR', 'Färger', false)
    ];

    constructor() {
        window.pickerStore = this;
    }

    async init(cisCode) {
        this.reset();

        this.client = new Picker(
            new IsomorphicTransport(
                'https://spark.fbinhouse.se/proxy/20/VolvoBoosterJson.ashx'
            )
        );

        await this.client.init('FULL_TEXT', 'VolvoCare');

        if (cisCode) {
            await this.loadByCisCode(cisCode);
        }

        await this.applyStateUpdate(false);
    }

    reset() {
        this.selectedModelId = '';
        this.selectedModelName = '';

        this.menus.forEach((menu) => {
            menu.items.replace([]);
            menu.value = '';
        });

        this.options.forEach((option) => {
            option.updateSelectedItems([]);
            option.updateMenuItems([]);
        });
    }

    async loadByCisCode(cisCode) {
        await this.client.loadConfig(
            new Config(
                'volvo_cis_car_specification_reduced',
                cisCode
            )
        );

        await this.applyStateUpdate();
    }

    async changeModel(modelId) {
        this.selectedModelId = modelId;

        if (!this.selectedModel) {
            this.reset();
            return;
        }

        await this.loadByCisCode(this.selectedModel.config.data);

        this.salesVersion = this.menus[2].value

        // Resetting selected values on model change
        this.options[0].selectedItems = [];
        this.options[1].selectedItems = [];
    }

    async selectMenuItem(key, itemId) {
        const item = this.stateUpdate.menuItems.find(
            (item) => item.id === itemId
        );

        this.menus.find((menu) => menu.key === key).value = itemId;

        await this.client.toggleMenuItem(item);

        await this.applyStateUpdate();
    }

    get selectedModel() {
        return this.models.find((model) => model.modelId === this.selectedModelId);
    }

    get selectedOptionTab() {
        if (this.selectedOptionsTabKey) {
            return this.options.find(
                (option) => option.key === this.selectedOptionsTabKey
            );
        }

        return this.options[0];
    }

    async getData() {
        return {
            cisCode: this.cisCode,
            images: await this.getImages(),
            options: this.getOptions()
        };
    }

    getOptions() {
        return this.options.reduce((options, option) => {
            options[option.key] = option.asJson();
            return options;
        }, {});
    }

    async getImages() {
        const series = this.client.vds.sessionGetImageSerieInfos();
        const promises = [];
        const images = [];

        for (const serie of series) {
            for (const frame of serie.framesOrder) {
                promises.push(
                    this.getImage(serie.name, frame)
                        .then((image) => images.push(image))
                );
            }
        }

        await Promise.all(promises);

        return images;
    }

    async getImage(serie, frame) {
        const result = await axios.get(
            `https://static.fbinhouse.se/spark/${this.cisCode}/${serie}/${frame}`
        );

        if (!result.data.ok) {
            return null;
        }

        return 'https://static.fbinhouse.se/' + result.data.fileName;
    }

    async applyStateUpdate(setModelId = true) {
        this.models.replace(this.client.models);
        this.stateUpdate = this.client.vds.sessionGetStateUpdate();

        if (setModelId) {
            this.selectedModelId = parseInt(this.stateUpdate.modelId.split('~')[1], 10);
            this.selectedModelName = this.stateUpdate.subscriptionIds[0];
        }

        this.populateMenus();

        const config = await this.client.vds.sessionGetConfig(
            'volvo_cis_car_specification_reduced'
        );

        this.cisCode = config.data;
    }

    populateMenus() {
        if (!this.selectedModelId) {
            return;
        }

        this.menus.forEach((menu) => {
            const items = this.client.extractMenuItemsByMenuId(menu.menuId);
            const selectedItem = items.find((item) => item.selected);

            runInAction(() => {
                menu.value = selectedItem ? selectedItem.id : '';
                menu.items.replace(items);
            });
        });

        this.options.forEach((option) => {
            const items = this.client.extractMenuItemsByMenuId(option.menuId);

            option.updateMenuItems(items);
        });
    }
}

decorate(PickerStore, {
    models: observable,
    selectedOptionsTabKey: observable,
    selectedModel: computed,
    selectedModelId: observable,
    selectedModelName: observable,
    stateUpdate: observable.ref,
    menus: observable,
    options: observable,
    salesVersion: observable,
    selectedOptionTab: computed,
    cisCode: observable,
    carData: observable
});

export default new PickerStore();
