import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import pickerStore from '../../../../stores/pickerStore';
import AddDialog from './AddDialog';
import EditDialog from './EditDialog';
import { styled } from '@material-ui/styles';

import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Checkbox
} from '@material-ui/core';


const CustomTableCell = styled(TableCell)({
  padding: '0'
});


class OptionTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // extraPackages: [],
            openEditDialog: false,

        };
    }

    addPackage = (name, description, price) => {
        /*
        this.setState(prevState => ({
            extraPackages: [
                ...prevState.extraPackages, {
                    name: name,
                    price: price,
                    description: description,
                    custom: true
                  }
            ]
        }));
        */

        // Adding new package to selectedItems
        pickerStore.options[0].selectedItems.push({name: name, price: price, description: description, custom: true})
        if (!('price' in pickerStore.carData)) {
            pickerStore.carData.price = [{
                additional: []
            }];
        }
        pickerStore.carData.price[0].additional.push({name: name, price: price, description: description, custom: true});
    }

    editPackage = (index, name, description, price) => {
        //console.log("Editing package", index, name, description, price);
        pickerStore.options[0].selectedItems.splice(index, 1, {name: name, price: price, description: description, custom: true});
        pickerStore.carData.price[0].additional.splice(index, 1, {name: name, price: price, description: description, custom: true});
    }


    handleCheckbox(e) {
        const name = e.target.attributes['name'].value;
        const description = e.target.attributes['description'].value;
        const price = e.target.attributes['price'].value;
        //const id = e.target.attributes['id'].value;
        const checked = e.target.checked;

        if(checked === false) {
            var index = pickerStore.options[0].selectedItems.map(x => {
              return x.name;
          }).indexOf(name);

            pickerStore.options[0].selectedItems.splice(index, 1);
        } else if(checked === true) {
            pickerStore.options[0].selectedItems.push({name: name, price: Number(price), description: description, custom: true})
        }
    }

    editPackageDialog(item, index) {
        this.setState({
            openEditDialog: true,
            editDialogItem: item,
            editDialogIndex: index,
        })
    }

    closeEdit() {
        this.setState({
            openEditDialog: false,
            editDialogItem: null,
            editDialogIndex: null,
        })
    }

    render() {
        const option = this.props.option;
        return <div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={option.areAllItemsSelected}
                                onChange={() => option.toggleAllItems()}
                            />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Namn</TableCell>
                        {option.hasDescription && <TableCell>Beskrivning</TableCell>}
                        {!option.hasDescription && <TableCell>Standard</TableCell>}
                        <TableCell>Pris</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {option.items.map((item, index) => {
                        return <TableRow key={index}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={option.isItemSelected(item)}
                                    onChange={() => option.toggleItem(item)}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            {option.hasDescription && (
                                <TableCell>
                                    <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                </TableCell>
                            )}
                            {!option.hasDescription && (
                                <TableCell>
                                    <Radio
                                        checked={item.default}
                                        disabled={!option.isItemSelected(item)}
                                        onChange={(event) => option.onItemRadioChanged(item, 'default', event.target.checked)}
                                        name="colorRadio"
                                  />
                                </TableCell>
                            )}
                            <CustomTableCell>
                                <TextField
                                    value={item.price}
                                    onChange={(event) => option.onItemChanged(item, 'price', event.target.value)}
                                    margin="none"
                                    disabled={!option.isItemSelected(item)}
                                />
                            </CustomTableCell>
                        </TableRow>
                    })}


                    {/* ---- Renders added packages ---- */}
                    {
                        pickerStore.selectedOptionTab.key === 'package' && Object.keys(pickerStore.carData).length ?
                        pickerStore.carData.price[0].additional.map((item, index) => {
                            if(item.custom === true) {
                                return <TableRow key={"additional_" + index}>
                                    <CustomTableCell padding="checkbox">
                                        <Checkbox
                                            inputProps={{id: item._id, name: item.name, description: item.description, price: item.price}}
                                            defaultChecked={true}
                                            onChange={this.handleCheckbox}
                                        />
                                    </CustomTableCell>
                                    <TableCell style={{color: '#007bcd', cursor: 'pointer', textAlign: 'center', textDecoration: 'underline'}} onClick={() => this.editPackageDialog(item, index)}>Ändra</TableCell>
                                    <TableCell>Custom</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                        </TableCell>
                                    <CustomTableCell>
                                        <TextField
                                            value={item.price}
                                            margin="none"
                                            disabled={true}
                                        />
                                    </CustomTableCell>
                                </TableRow>
                            } else { return null }
                        })
                        : null
                    }


                    {/* ---- Adding new packages ---- */}
                    {
                        /*
                        pickerStore.selectedOptionTab.key === 'package' ?
                        this.state.extraPackages.map((item, index) => {
                            if(item.custom === true) {
                                return <TableRow key={item._id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            inputProps={{id: item._id, name: item.name, description: item.description, price: item.price}}
                                            defaultChecked={true}
                                            onChange={this.handleCheckbox}
                                        />
                                    </TableCell>
                                    <TableCell onClick={() => this.editPackage(item._id)}>Ändra</TableCell>
                                    <TableCell>Custom</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            <div dangerouslySetInnerHTML={{ __html: item.description }}/>
                                        </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={item.price}
                                            margin="none"
                                        />
                                    </TableCell>
                                </TableRow>
                            } else { return null }
                        })
                        : null
                        */
                    }

                </TableBody>
            </Table>
            {
                pickerStore.selectedOptionTab.key === 'package' && (this.props.carSelected || pickerStore.carData.carModel) ?
                    <AddDialog
                        addPackage={this.addPackage}
                    />
                : null
            }

            {
                this.state.openEditDialog ?
                    <EditDialog
                        show={this.state.openEditDialog}
                        closeEdit={() => {this.closeEdit()}}

                        item={this.state.editDialogItem}
                        index={this.state.editDialogIndex}

                        editPackage={(i, name, description, price) => {this.editPackage(i, name, description, price)}}
                    />
                : null }

        </div>
    }
}

export default observer(OptionTable);
