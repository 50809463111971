import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';


export default class EditDialog extends React.Component {
  state = {
    open: false,
    name: this.props.item.name,
    price: this.props.item.price.toString(),
    description: this.props.item.description,
    showError: false
  };

  handleClickOpen = () => {
    this.setState({
        open: true,
        name: '',
        price: 0,
        description: '',
        showError: false
    });

  };

  handleClose = () => {
      this.setState({
          open: false,
          name: '',
          price: 0,
          description: '',
          showError: false
      });

      this.props.closeEdit();
  };


  onNameChange = (event) => {
      const target = event.target;
      const value = target.value;

      this.setState({
          name: value
      })
  }

  onDescriptionChange = (event) => {
      const target = event.target;
      const value = target.value;

      this.setState({
          description: value
      })
  }

  onPriceChange = (event) => {
      const target = event.target;
      const value = target.value;

      this.setState({
          price: value
      })
  }

  validateFields = () => {
      let name = this.state.name;
      let desc = this.state.description;
      let price = this.state.price;

      if(name.length > 0 && desc.length > 0 && price.length > 0) {

          this.addPackage();

          this.setState({
              showError: false
          })
      } else {
          this.setState({
              showError: true
          })
      }
  }

  addPackage = () => {
      this.props.editPackage(this.props.index, this.state.name, this.state.description, this.state.price);
      this.handleClose();

      this.setState({
          name: '',
          description: '',
          price: 0
      })
  }


  render() {
    return (
      <div>

        <Button variant="contained" style={{marginTop: '20px'}} onClick={this.handleClickOpen}>
          <Icon>add</Icon>
        </Button>

        <Dialog
          open={this.props.show}
          onClose={this.handleClose}
          aria-labelledby="Lägg-till-paket"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Ändra paket</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Namn"
              type="text"
              fullWidth
              onChange={this.onNameChange}
              value={this.state.name}
            />
          </DialogContent>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="description"
              label="Beskrivning"
              type="text"
              fullWidth
              multiline={true}
              onChange={this.onDescriptionChange}
              value={this.state.description}
            />
          </DialogContent>

          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="price"
              label="Pris"
              type="number"
              fullWidth
              onChange={this.onPriceChange}
              value={this.state.price}
            />
          </DialogContent>

          {this.state.showError ? <p className="showError" style={{marginLeft:'20px', color: '#ff4b4b'}}>Vänligen fyll i alla fält.</p> : null}

          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Avbryt
            </Button>
            <Button onClick={this.validateFields} color="primary">
              Spara
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
