import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  cardAction: {
    display: 'block',
    textAlign: 'initial',
    width: '100%',
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
});

class SellerItem extends React.Component {
  constructor(props) {
    super(props);

    this.reset = this.reset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.state = {
      new: (this.props.value.hash) ? false : true,
      editing: (this.props.value.hash) ? false : true,
      changed: false,
      hash: this.props.value.hash,
      csid: this.props.value.csid,
      email: this.props.value.email,
    };
  }

  reset() {
    this.setState({
      changed: false,
      editing: false,
      hash: this.props.value.hash,
      csid: this.props.value.csid,
      email: this.props.value.email,
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.id;

    this.setState({
      [name]: value,
      changed: true,
    });
  }

  onCancel() {
    this.reset();
  }

  onSave() {
    if(this.state.new) {
      this.props.onCreate({csid: this.state.csid, email: this.state.email});
    } else {
      this.props.onChange(this.state.hash, {csid: this.state.csid, email: this.state.email});
    }
    this.setState({ editing: false });
  }

  onDelete() {
    this.props.onDelete(this.state.hash);
  }

  render() {
    return (
      <Paper elevation={1} square={true} className={this.props.classes.container}>
        <Grid container direction="row">
          <Grid item container xs direction="column">
            <Grid item>
              <ButtonBase
                className={this.props.classes.cardAction}
                onClick={e => {this.setState({editing: true})}}>
                <Grid container direction="row" spacing={24}>
                  {this.state.hash ?
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography><strong>Hash</strong></Typography>
                        </Grid>
                        <Grid item>
                          <Typography>{this.state.hash}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    : null
                  }
                  <Grid item>
                    {this.state.editing ?
                      <React.Fragment>
                        <TextField
                          required
                          fullWidth
                          id="csid"
                          label="csid"
                          margin="normal"
                          value={this.state.csid}
                          onChange={this.handleChange} />
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="E-post"
                          margin="normal"
                          value={this.state.email}
                          onChange={this.handleChange} />
                      </React.Fragment>
                      :
                      <Grid container direction="column">
                        <Grid item>
                          <Typography variant="caption">csid</Typography>
                          <Typography gutterBottom>{this.state.csid}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">E-post</Typography>
                          <Typography>{this.state.email}</Typography>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </ButtonBase>
            </Grid>

            {this.state.editing ?
              <React.Fragment>
                <Divider />
                <Grid item>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Button color="secondary" disabled={this.state.new} onClick={this.onDelete}>Ta bort</Button>
                    </Grid>
                    <Grid item>
                      <Button onClick={this.onCancel}>Avbryt</Button>
                    </Grid>
                    <Grid item>
                      <Button disabled={!this.state.changed} onClick={this.onSave}>Spara</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
              : null
            }
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="Kopiera"
              href={`${window.PUBLIC_URL}/?seller=${this.state.hash}`}
              target="_blank">
              <Icon>link</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(SellerItem);
