import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import pickerStore from '../../stores/pickerStore';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Equipment from './Equipment';
import Cis from './Cis';
import cbvApi from '../../lib/cbvApi';
import dmsApi from '../../lib/dmsApi';
import techDataApi from '../../lib/techDataApi';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
  section: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class CarForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleEquipmentChange = this.handleEquipmentChange.bind(this);
    this.addEquipment = this.addEquipment.bind(this);
    this.removeEquipment = this.removeEquipment.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.updateEquipmentList = this.updateEquipmentList.bind(this);
    this.onCISupdate = this.onCISupdate.bind(this);

    this.state = {
      carModel: '',
      cis: '',
      equipment: [],
      images: [],
      id: '',
      name: '',
      price: [],
      promoted: false,
      promotedName: '',
      promotedTitle: '',
      tags: [],
      title: '',
      techData: '',
      order: 0,
      subtitle: ''
    };
  }

  componentDidMount() {
    cbvApi.getCar(this.props.match.params.id).then(car => {
      pickerStore.carData = car;
      var id = 0;
      var equipment = car.equipment.map(item => {
        item.id = id++;
        return item;
      });
      this.setState({
        carModel: car.carModel,
        images: car.images,
        price: car.price,
        name: car.name,
        title: car.title,
        order: car.order || 0,
        equipment: equipment,
        cis: car.ciscode,
        id: car._id,
        promoted: car.promoted,
        tags: car.tags,
        techData: car.techData,
        popular: car.tags.indexOf("Popular") > -1 ? true : false,
        promotedName: car.promotedName ? car.promotedName : '',
        promotedTitle: car.promotedTitle ? car.promotedTitle : ''
      });


      car.price[0].colors.map((color, index) => (
          pickerStore.options[1].selectedItems.push(color)
      ))

      car.price[0].additional.forEach((item, index) => {
          pickerStore.options[0].selectedItems.push(item)
      })
      return car;

    }).catch(e => {
      console.log(e);
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  }

  handlePriceChange(event) {
      const target = event.target;
      const value = target.value;

      const price = this.state.price;
      price[0].basePrice = value;
      this.setState({ ...this.state, price });
  }

  handleEquipmentChange(id, event) {
    this.setState(prevState => {
      const equipment = prevState.equipment;
      equipment[id] = event;
      return Object.assign({}, prevState, { equipment: equipment })
    });
  }

  addEquipment() {
    this.setState(prevState => ({
      equipment: [...prevState.equipment, { description: '' }]
    }));
  }

  removeEquipment(index) {
    var equipment = [...this.state.equipment];
    equipment.splice(index, 1);
    this.setState({ equipment: equipment });
  }

  handleSave() {
    let name = this.state.name;
    let carModel = pickerStore.selectedModelName;
    let ciscode = pickerStore.cisCode;
    let equipment = this.state.equipment;
    let images = this.state.images;
    let price = this.state.price[0];
    let promoted = this.state.promoted;
    let tags = this.state.tags;
    let techData = this.state.techData;
    let title = this.state.title;
    let order = this.state.order;
    let id = this.state.id;
    let additional = [];
    let colors = [];
    let popular = this.state.popular;
    let promotedName = this.state.promotedName;
    let promotedTitle = this.state.promotedTitle;

    // Tillval
    pickerStore.options[0].selectedItems.forEach((item, index) => {
        additional.push({id: item.id, name: item.name, price: item.price, description: item.description, custom: item.custom ? item.custom : false})
    })

    price.additional = additional;


    // Colors
    pickerStore.options[1].selectedItems.forEach((item, index) => {
        colors.push({id: item.id, cis: item.cis, price: item.price, name: item.name, default: item.default, icon: window.BACKEND_URL + '/icons/' + pickerStore.client.client.sessionState.categoryId + '/' + pickerStore.selectedModelId + '/' + item.id + '.png'})
    })
    price.colors = colors;


    // Popular
    if(popular) {
        for (var i=tags.length-1; i>=0; i--) {
            if (tags[i] === 'Popular') {
                tags.splice(i, 1);
            }
        }

        tags.push('Popular')
    } else {
        //tags = tags.filter(e => e !== 'Popular');
        for (var l=tags.length-1; l>=0; l--) {
            if (tags[l] === 'Popular') {
                tags.splice(l, 1);
            }
        }
    }

    let carData = {name, carModel, ciscode, equipment, images, order, price, promoted, promotedName, promotedTitle, tags, techData, title}

        // Adding default color code to CIS
        for (var y=0, iLen=carData.price.colors.length; y<iLen; y++) {
            if (carData.price.colors[y].default === true) {
                var newCis = pickerStore.cisCode.substring(0, 14) + carData.price.colors[y].cis + pickerStore.cisCode.substring(17);
                carData.ciscode = newCis;
            }
        }

        // Genereating new image links
        var carcis = carData.ciscode;
        var hostImg = 'https://static.fbinhouse.se/sparkProxy/';
        var imgsize = '1200x/';
        var interiorimg = '/INTERIOR/';
        var exteriorimg = '/EXTERIOR/';

        var imageArr = [];

        for (var x = 1; x < 10; x++) {
            if (x < 7) {
                imageArr.push(hostImg + imgsize + carcis + exteriorimg + x + '.png')
            } else {
                imageArr.push(hostImg + imgsize + carcis + interiorimg + (x - 6) + '.png')
            }
        }

        carData.images = imageArr;

        cbvApi.updateCar(id, carData);

        this.props.history.push('/cars');
  }

  updateEquipmentList(salesVersion) {
      let modelId = Number(pickerStore.selectedModelId);
      let salesVersionId = salesVersion ? salesVersion : pickerStore.salesVersion;

      this.setState({equipment: []});

      dmsApi.getSalesVersions()
        .then(result => {
          const filtered = result.filter(salesVersion => {
            return salesVersion.values.menu_id === salesVersionId;
          });

          const reFiltered = filtered.filter(salesVersion => {
            return salesVersion.modelCodes.includes(modelId);
          });

          return reFiltered;
        })
        .then(result => {
          let eqArr = [];
          if(result[0]) {
              result[0].values.options.option.forEach((item, index) => {
                  eqArr.push({
                      description: item.name,
                      additional: item.description ? item.description : null
                  })
              })
              this.setState({equipment: []});
              this.setState({equipment: eqArr});
          }
      });
  }

  getTechData(ciscode) {
      return techDataApi.getTechData(ciscode);
  }

  onCISupdate(val) {
      if(pickerStore.cisCode && pickerStore.cisCode !== this.state.cis) {
          this.getTechData(pickerStore.cisCode)
          .then(result => {
              this.setState({
                  techData: result
              });
          });
      }
      return
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <form>
          <Grid container justify="center" >
            <Grid item xs={12} md={10}>
              <Paper elevation={1} className={this.props.classes.container}>
                <Grid item xs className={this.props.classes.section}>
                  <Fab color="primary" size="small" aria-label="Tillbaka" component={Link} to="/cars">
                    <Icon>arrow_back</Icon>
                  </Fab>
                </Grid>
                <Grid item xs className={this.props.classes.section}>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Namn"
                        margin="normal"
                        value={this.state.name}
                        onChange={this.handleChange} />

                    <TextField
                        required
                        fullWidth
                        id="title"
                        label="Titel"
                        margin="normal"
                        value={this.state.title}
                        onChange={this.handleChange} />

                    <TextField
                        required
                        fullWidth
                        id="price"
                        label="Baspris"
                        margin="normal"
                        value={this.state.price.length > 0 ? this.state.price[0].basePrice : ''}
                        onChange={this.handlePriceChange} />

                    <TextField
                        fullWidth
                        id="order"
                        label="Sortering"
                        margin="normal"
                        value={this.state.order}
                        onChange={this.handleChange} />

                    <TextField
                        fullWidth
                        id="techData"
                        label="Teknisk data"
                        margin="normal"
                        value={this.state.techData}
                        onChange={this.handleChange} />
                </Grid>

                <Grid item xs className={this.props.classes.section} style={{display: 'flex', alignItems: 'center', paddingTop: '0', paddingBottom: '0'}}>
                    <Typography variant="subheading">
                      Populär:
                    </Typography>
                    <Checkbox
                        onChange={this.handleChange}
                        checked={this.state.popular ? true : false}
                        id="popular"
                    />

                    <Typography variant="subheading">
                      Promotad:
                    </Typography>
                    <Checkbox
                        onChange={this.handleChange}
                        checked={this.state.promoted ? true : false}
                        id="promoted"
                    />
                </Grid>

                { this.state.promoted ? <Grid>
                    <TextField
                        style={{marginTop: 0, marginBottom: '20px'}}
                        fullWidth
                        id="promotedName"
                        label="Titel för Promotad bil"
                        margin="normal"
                        value={this.state.promotedName}
                        onChange={this.handleChange} />
                </Grid> : null }

                { this.state.promoted ? <Grid>
                    <TextField
                        style={{marginTop: 0, marginBottom: '20px'}}
                        fullWidth
                        id="promotedTitle"
                        label="Subtitle för Promotad bil"
                        margin="normal"
                        value={this.state.promotedTitle}
                        onChange={this.handleChange} />
                </Grid> : null }

                <Grid item xs className={this.props.classes.section}>
                    {this.state.cis ?
                        <Cis
                            value={this.state.cis}
                            updateEquipmentList={this.updateEquipmentList}
                            onCISupdate={this.onCISupdate}
                        />
                    : null }
                </Grid>

                <Grid item xs className={this.props.classes.section}>
                  <Typography component="h2" variant="h5">
                    Utrustning
                  </Typography>

                  {this.state.equipment.map((item, index) =>
                    <Equipment
                      equipment={item}
                      onChange={e => {this.handleEquipmentChange(index, e)}}
                      onRemove={this.removeEquipment}
                      key={item.id}
                      id={index} />
                  )}

                  <Button variant="contained" onClick={this.addEquipment}>
                    <Icon>add</Icon>
                  </Button>
                </Grid>

                <Grid item xs className={this.props.classes.section}>
                  <Button variant="contained" color="primary" onClick={this.handleSave}>
                    Spara
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(CarForm));
