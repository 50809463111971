import store from 'store';
import Api from './api';

class cbvApi extends Api {
  constructor(backendEndPoint) {
    let headers = {};
    const token = store.get('token') || null;

    if(token) {
      headers = {'Authorization': `bearer ${token}`};
    }

    super(backendEndPoint, headers);
  }

  getCars() {
    return this.makeGet('/car');
  }

  getCar(id) {
    return this.makeGet(`/car/${id}`);
  }

  addCar(data) {
    return this.makePost('/car', data);
  }

  updateCar(id, data) {
    return this.makePatch(`/car/${id}`, data);
  }

  deleteCar(id) {
    return this.makeDelete(`/car/${id}`);
  }

  getSellers() {
    return this.makeGet('/seller');
  }

  getSeller(id) {
    return this.makeGet(`/seller/${id}`);
  }

  addSeller(data) {
    return this.makePost('/seller', data);
  }

  updateSeller(id, data) {
    return this.makePatch(`/seller/${id}`, data);
  }

  deleteSeller(id) {
    return this.makeDelete(`/seller/${id}`);
  }
}

export default new cbvApi(window.BACKEND_URL)
