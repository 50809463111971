import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import CarList from './components/CarList';
import CarForm from './components/CarForm';
import CarFormAdd from './components/CarFormAdd';
import Index from './components/Index';
import SellerList from './components/SellerList';
import './App.css';

class App extends Component {
  constructor() {
    super();

    this.state = {
      carId: null,
    }
  }

  onSelect(carId) {
    this.setState({ carId: carId });
  }

  render() {
    return (
      <Router>
        <div className="body-container">
          <CssBaseline />
          <Typography component="h1" variant="h3" align="center" gutterBottom>VCS Privatleasing</Typography>
          <Route exact path="/" component={Index} />
          <Route exact path="/cars" component={CarList} />
          <Route path="/cars/:id" component={CarForm} />
          <Route path="/sellers" component={SellerList} />
          <Route path="/addCar" component={CarFormAdd} />
        </div>
      </Router>
    );
  }
}

export default App;
